let SMPNum = require("../../SMPNum");
let SMPMathCore = require("../classes/SMPMathCore");

class SMPMathGamePlay {
    constructor(ratios) {
        this.mathCore = new SMPMathCore(ratios);

        /*test to define level base on gold
        let gold = new SMPNum(16984.00081024063);//SMPNum.fromPowerString('182.4020980003094');
        console.log("GoldInit: "+gold.ToReadableAlphabetV2());
        let lv = this.GetMaxLevelThatCanBuyWithExistingGold(gold, 1, 'CostSupport');
        console.log("Lv: " + lv);*/
    }

    updateRatio(ratios){
        this.mathCore.updateRatio(ratios);
    }

    GetUnBaseOnLevel(level, sequenceName) {
        if (level === 0) return new SMPNum(0);
        let firstTerm = this.GetSeriesFirstTermByLevel(level, sequenceName);
        return this.GetUnBaseOnLevelAndFirstTerm(level, firstTerm, sequenceName);
    }

    GetSeriesFirstTermByLevel(level, sequenceName) {
        return this.mathCore.GetSeriesFirstTermByLevel(level, sequenceName);
    }

    GetUnBaseOnLevelAndFirstTerm(level, firstTerm, sequenceName) {
        let firstTermOfSequence = firstTerm;
        let termMatchingLevel = this.GetTermValueForSeries(level, sequenceName);
        return SMPNum.multSmpNum(termMatchingLevel, firstTermOfSequence);
    }

    GetTermValueForSeries(level, sequenceName) {
        return this.mathCore.GetTermValueForSeries(level, sequenceName);
    }

    GetSeriesCommonRatioByLevel(level, sequenceName) {
        return this.mathCore.GetSeriesCommonRatioByLevel(level, sequenceName);
    }

    /*take from game play*/
    GetMaxLevelThatCanBuyWithExistingGold(_gold, _currentLv, sequenceName) {
        let level = 0;
        //let sequenceIntervals = this.mathCore.getSequences(sequenceName);
        let maxSequence = this.mathCore.GetMaxIntervalIndex();
        let interval;
        let preInterval;
        for (let i = 0; i <= maxSequence; i++) {
            interval = this.mathCore.GetSequenceInteral(i);//sequenceIntervals[i];

            if (interval.max <= _currentLv) {
                continue;
            }

            let cmRatio = this.mathCore.GetSeriesCommonRatio(i, sequenceName);
            let firstTerm = this.mathCore.GetSeriesFirstTermByLevel(interval.start, sequenceName);
            let newFirstTerm = firstTerm;
            let newCurrentLv = 0;

            let cost = new SMPNum(0);
            let levelToGet = 0;

            if (i === 0) {
                newCurrentLv = _currentLv;
                levelToGet = interval.max - _currentLv;
                cost = this.mathCore.GeometrySum(levelToGet, firstTerm, cmRatio, _currentLv + 1);
            } else {
                preInterval = this.mathCore.GetSequenceInteral(i-1);
                if (_currentLv >= interval.start && _currentLv <= interval.max) {
                    levelToGet = interval.max - _currentLv;
                    newCurrentLv = _currentLv - preInterval.max;
                } else {
                    levelToGet = interval.max - preInterval.max;
                }

                newFirstTerm = this.GetUnBaseOnLevelAndFirstTerm(interval.start, firstTerm, sequenceName);
                cost = this.mathCore.GeometrySum(levelToGet, newFirstTerm, cmRatio, newCurrentLv + 1);
            }

            if (SMPNum.greaterThan(_gold, cost)){
                level += levelToGet;
                _gold = SMPNum.minus(_gold, cost);
            } else {
                let lv = this.mathCore.GetUnUsingSum(_gold, newFirstTerm, cmRatio, newCurrentLv + 1);
                level += lv;
                //console.log("Gold: " + _gold.ToReadableAlphabetV2() + " can buy Level : " + level);
                break;
            }
        }

        return parseInt(level);
    }


    //after testing support this method not correct
    SumBaseOnCurrentLvAndTargetLv(currentLv, targetLv, sequenceName) {
        if(targetLv <= currentLv) return new SMPNum(0);

        let sum = new SMPNum(0);
        //let sequenceIntervals = this.mathCore.getSequences(sequenceName);
        let currentInterval = this.mathCore.GetIntervalIndex(targetLv);

        let cmRatio;
        let firstTerm;
        let interval;
        let preInterval;

        for (let i = 0; i <= currentInterval.indexInterval; i++) {
            interval = this.mathCore.GetSequenceInteral(i);//sequenceIntervals[i];
            if (interval.max <= currentLv) {
                continue;
            }

            cmRatio = this.mathCore.GetSeriesCommonRatio(i, sequenceName);
            firstTerm = this.mathCore.GetSeriesFirstTermByLevel(interval.start, sequenceName);
            let newCurrentLv = 0;

            if (interval.max < targetLv) {
                let newFirstTerm = firstTerm;
                let newN = interval.max - currentLv;
                if (i === 0) {
                    newCurrentLv = currentLv;
                } else {
                    preInterval = this.mathCore.GetSequenceInteral(i-1);
                    if (currentLv >= interval.start && currentLv <= interval.max) {
                        newN = interval.max - currentLv;
                        newCurrentLv = currentLv - preInterval.max;
                    } else {
                        newN = interval.max - preInterval.max;
                    }

                    newFirstTerm = this.GetUnBaseOnLevelAndFirstTerm(preInterval.max + 1, firstTerm, sequenceName);
                }

                let sumPart = this.mathCore.GeometrySum(newN, newFirstTerm, cmRatio, newCurrentLv + 1);
                sum = SMPNum.plus(sum, sumPart);
            } else {
                let newFirstTerm = firstTerm;
                let newN = targetLv - currentLv;
                if (i === 0) {
                    newCurrentLv = currentLv;
                } else {
                    preInterval = this.mathCore.GetSequenceInteral(i-1);
                    if (currentLv >= interval.start && currentLv <= interval.max) {
                        newN = targetLv - currentLv;
                        newCurrentLv = currentLv - preInterval.max;
                    } else {
                        newN = targetLv - preInterval.max;
                    }

                    newFirstTerm = this.GetUnBaseOnLevelAndFirstTerm(preInterval.max + 1, firstTerm, sequenceName);
                }

                sum = SMPNum.plus(sum, this.mathCore.GeometrySum(newN, newFirstTerm, cmRatio, newCurrentLv + 1));
            }
        }
        return sum;
    }

    //merge with old SMPGameplay.js

    ComputeSumWithLoop(seriesName, lvStart, lvTarget, step){
        if(lvTarget < 1 || lvTarget < lvStart){
            return new SMPNum(0);
        } else {
            let sum = new SMPNum(0);
            //let value = 1;
            for(let lv = lvStart; lv<=lvTarget; lv+= step){
                let firstTermOfSequence = this.GetSeriesFirstTermByLevel(lv, seriesName);
                let termValue = this.GetTermValueForSeries(lv, seriesName);
                sum = SMPNum.plus(sum,SMPNum.multSmpNum(firstTermOfSequence, termValue));
            }
            return sum;
        }
    }

    ComputeSumGeometricForSeries(seriesName,level) {
        if(level < 1){
            return new SMPNum(0);
        } else {
            let commonRatio = this.GetSeriesCommonRatioByLevel(level, seriesName);
            let numRatio = SMPNum.fromNum(commonRatio);
            let numerator = SMPNum.pow(numRatio, level);
            let smpOne = new SMPNum(1);
            if(SMPNum.greaterThan(numerator, smpOne)){
                numerator = SMPNum.minus(numerator, smpOne);
            } else{
                numerator = new SMPNum(0);
            }
            let denominator = new SMPNum(0);
            if(SMPNum.greaterThan(numRatio, smpOne)){
                denominator = SMPNum.minus(numRatio, smpOne);
            }
            let firstTerm = this.GetSeriesFirstTermByLevel(1, seriesName);
            let temp = SMPNum.multSmpNum(firstTerm, numerator);
            if(!denominator.isZero){
                return SMPNum.divSmpNum(temp,denominator);
            } else {
                return new SMPNum(0);
            }
        }
    }

    ComputeRemainingSumForSeries(seriesName, level) {
        let all = this.ComputeSumForWholeSeries(seriesName, level);
        let commonRatio = this.mathCore.GetSeriesCommonRatioByLevel(level, seriesName);
        let numRatio = SMPNum.fromNum(commonRatio);
        let numerator = SMPNum.pow(numRatio, level);
        numerator = SMPNum.minus(numerator, new SMPNum(1));
        let denominator = SMPNum.minus(numRatio, new SMPNum(1));
        let temp = SMPNum.divSmpNum(numerator, denominator);
        let firstTerm = this.mathCore.GetSeriesFirstTermByLevel(1, seriesName);
        let sumToLevel = SMPNum.multSmpNum(firstTerm, temp);
        return SMPNum.minus(all, sumToLevel);
    }

    ComputeSumForWholeSeries(seriesName, level) {
        console.log('not use but was calling '+seriesName+' level '+level);
        return null;
        // let gameInterval = this.mathCore.getSequenceIntervalInfoFor(level, seriesName);
        // let commonRatio = this.mathCore.GetSeriesCommonRatioByLevel(level, seriesName);
        // let numRatio = SMPNum.fromNum(commonRatio);
        // let numerator = SMPNum.pow(numRatio, gameInterval.max);
        // numerator = SMPNum.minus(numerator, new SMPNum(1));
        // let denominator = SMPNum.minus(numRatio, new SMPNum(1));
        // let temp = SMPNum.divSmpNum(numerator, denominator);
        // let firstTerm = this.GetTermValueForSeries(level,seriesName);
        // return SMPNum.multSmpNum(firstTerm, temp);
    }
}

module.exports = SMPMathGamePlay;